<template>
  <div>
    <h1>{{ id ? '修改' : '新建' }}菜单</h1>
    <el-form @submit.native.prevent="save" class="el-form--max-width-400">
      <el-form-item label="菜单名">
        <el-input v-model="model.name" />
      </el-form-item>
      <el-form-item label="URL">
        <el-input v-model="model.url" />
      </el-form-item>
      <el-form-item label="图标">
        <el-input v-model="model.icon" />
      </el-form-item>
      <el-form-item label="父级菜单">
        <el-cascader
          v-model="model.parent_id"
          :options="menus"
          :props="{
            value: 'id',
            label: 'name',
            checkStrictly: true,
            emitPath: false,
          }"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="是否为CRUD型菜单">
        <el-switch v-model="model.crud" :active-value="1" :inactive-value="0">
        </el-switch>
      </el-form-item>
      <el-form-item label="是否隐藏菜单">
        <el-switch v-model="model.hidden" :active-value="1" :inactive-value="0">
        </el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { data2tree } from '@/utils/tree'
export default {
  props: {
    id: String,
  },
  data() {
    return {
      model: {
        name: '',
        url: '',
        icon: '',
        parent_id: '',
        crud: 0,
        hidden: 0,
      },
      menus: [],
    }
  },
  created() {
    this.id && this.fetch()
    this.fetchMenus()
  },
  methods: {
    async fetch() {
      const { data: menu } = await this.$http.get(`menus/${this.id}`)
      this.model = Object.assign(this.model, menu)
    },
    async fetchMenus() {
      const { data: menus } = await this.$http.get('menus?paginate=0&order=asc')
      this.menus = data2tree(menus, null)
    },
    async save() {
      if (this.id) {
        await this.$http.put(`menus/${this.id}`, this.model)
      } else {
        await this.$http.post('menus', this.model)
      }
      this.$router.push('/menu/list')
      this.$message.success('保存成功')
    },
  },
}
</script>
